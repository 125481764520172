import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Card,
  CardMedia,
  CardActions,
  CardContent,
  Button
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import psLogo from '../images/platformsciencebrand.png';

import {logout} from '@peoplenet/vi-components-ui/src/app/user/UserActions';


export class SignUpVerification extends Component {
  componentDidMount() {
    document.getElementById('backgroundColor').className = 'logingradient linkaccounts';
  }

  componentWillUnmount() {
    document.getElementById('backgroundColor').className = '';
  }

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    return (
      <Card className="linkaccounts__card">
        <CardMedia image={psLogo} className="linkaccounts__media linkaccounts__bg" />
        <CardContent className="linkaccounts__content">
          <div>
            <Typography type="headline" component="h2">
              <Icon className="linkaccounts__pending">email</Icon>
            </Typography>
            <Typography type="headline" component="h2">
              Verify Your Email
            </Typography>
            <Typography component="p" className="linkaccounts__centered">
              This account has not been verified. Please verify the email address to log in.
            </Typography>
          </div>
        </CardContent>
        <CardActions className="linkaccounts__actions">
          <Button variant="contained" color="primary" onClick={this.handleLogout} id="logout">
            Logout
          </Button>
        </CardActions>
      </Card>
    );
  }
}


SignUpVerification.defaultProps = {};

function mapStateToProps(state) {
  const {user, customer} = state;
  return {...user, customer};
}

export default connect(mapStateToProps, {logout})(SignUpVerification);
